.bg-menu-theme .menu-inner > .menu-item > .menu-link.active {color: #696cff;background-color: rgba(105, 108, 255, 0.16) !important;}
.srv-validation-message{color: red;}
.dt-scroll-headInner, .dt-scroll-headInner table, .dt-scroll-body, .dt-scroll-body table{width: 100% !important;}
.bg-menu-theme .menu-sub > .menu-item > .menu-link.active:not(.menu-toggle):before {background-color: #696cff !important;border: 3px solid #e7e7ff !important;}
.bg-menu-theme .menu-sub > .menu-item > .menu-link.active:not(.menu-toggle):before {left: 1.1875rem;width: 0.875rem;height: 0.875rem;}
.bg-menu-theme .menu-item.open:not(.menu-item-closing) > .menu-toggle, .bg-menu-theme .menu-item > .menu-link.active {color: #566a7f;font-weight: 600;}
.text-right{text-align: right;}
.react-datepicker-wrapper{width: 100%;}
.pointer{cursor: pointer;}
.loader-wrapper {position: fixed;top: 0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.5);z-index: 9999;}
.loader {border: 7px solid #f3f3f3; border-top: 7px solid #696cff;border-radius: 50%;width: 75px;height: 75px;animation: spin 2s linear infinite;}
.menu-item .menu-toggle::after {transform: rotate(137deg);}
.menu-item .menu-toggle.collapsed::after {transform: rotate(40deg);}

#dataTableExample_wrapper .dt-layout-table{overflow: auto;}
#dataTableExample_wrapper .dt-length select{margin-right: 10px;}
#dataTableExample_wrapper .dt-length label{text-transform: capitalize;}
#dataTableExample_wrapper .dt-search input{margin-left: 5px;}

.break-word {word-break: break-word;overflow-wrap: break-word;white-space: pre-wrap;min-width: 400px;}
.text-black{color: #373A40;}
.text-black1{color: #0C1844;}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}